import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function Crunchbase(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="-4 -4 32 32">
      <path d="M21 0a3 3 0 013 3v18a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zM6.575 9.029C4.535 9.269 3 10.975 3 13c0 2.025 1.535 3.73 3.575 3.971 2.04.241 3.94-1.059 4.425-3.027H9.17a2.336 2.336 0 01-2.627 1.261 2.3 2.3 0 01-1.804-2.266 2.302 2.302 0 011.867-2.216 2.334 2.334 0 012.59 1.333H11c-.485-1.968-2.385-3.268-4.425-3.027zM14.636 5H13v11.685h1.646v-.426l.18.121c1.408.89 3.222.82 4.56-.197a4.106 4.106 0 001.397-4.582 4.038 4.038 0 00-3.877-2.76h-.296l-.235.024a3.956 3.956 0 00-1.739.678V5zm2.178 5.62l.119.002a2.332 2.332 0 012.286 2.277c.008.104.008.208 0 .313v.072l-.027.144-.036.142c-.03.1-.067.2-.112.294v.036l-.084.161c-.329.575-.886.985-1.534 1.123l-.22.036h-.049l-.126.004-.125-.004c-.105 0-.21-.008-.314-.023h-.072l-.2-.042a2.275 2.275 0 01-.388-.139h-.044l-.184-.102a2.325 2.325 0 01-.336-.251 2.357 2.357 0 01-.408-.495 2.349 2.349 0 01-.12-2.217 2.32 2.32 0 011.855-1.329l.119-.002z" />
    </SvgIcon>
  );
}
